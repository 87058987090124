// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../../libs/ID.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderSelectionTile from "../_components/ProviderSelectionTile/ProviderSelectionTile.res.js";
import * as CreateNetworkProjectProvidersSelectionScss from "./CreateNetworkProjectProvidersSelection.scss";

var css = CreateNetworkProjectProvidersSelectionScss;

function CreateNetworkProjectProvidersSelection_SelectProvidersSection(props) {
  var userRole = props.userRole;
  var toggleProviderSelection = props.toggleProviderSelection;
  var selectedProviders = props.selectedProviders;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("h2", {
                      children: "Select Providers",
                      className: css.selectProvidersSectionHeading
                    }),
                JsxRuntime.jsx("p", {
                      children: "Deselect all",
                      className: css.deselectAll,
                      onClick: props.onDeselectAll
                    }),
                Belt_Array.map(props.providers, (function (provider) {
                        return JsxRuntime.jsx(ProviderSelectionTile.make, {
                                    provider: provider,
                                    checked: Js_array.includes(provider.id, selectedProviders),
                                    onToggle: (function (param) {
                                        toggleProviderSelection(provider);
                                      }),
                                    userRole: userRole,
                                    selectedProviders: selectedProviders
                                  }, ID.toString(provider.id) + "-Provider-Selection-Tile");
                      }))
              ]
            });
}

var make = CreateNetworkProjectProvidersSelection_SelectProvidersSection;

export {
  css ,
  make ,
}
/* css Not a pure module */
